<template>
  <div class="w-full">
    <SideBar
      title
      @hide="cancelar"
      :active="modalVisualizarCliente"
      :disabled="
        !$atividades.permissoes.includes(3) &&
        !$atividades.permissoes.includes(6) &&
        !$atividades.permissoes.includes(9) &&
        !$atividades.permissoes.includes(15)
      "
      icon="edit"
      :size="size"
      :visualizar="true"
      textSucces="Atualizar"
    >
      <div slot="body">
        <div
          class="w-full vs-con-loading__container"
          :id="'clienteVisualizar' + id_cliente"
        >
          <vs-tabs
            position="top"
            color="dark"
            alignment="center"
            id="profile-tabs"
          >
            <vs-tab
              icon-pack="feather"
              icon="icon-user"
              label="Cliente"
              class
              @click="size = 'large'"
            >
              <VuePerfectScrollbar
                class="scroll-visualizar-cliente"
                :settings="settings"
              >
                <div class="tab-general w-full">
                  <div class="px-3 py-3">
                    <vx-card>
                      <vs-divider
                        border-style="dashed"
                        color="success"
                        class="pt-5 my-0"
                      >
                        <span style="font-size: 17px; font-weight: 700"
                          >Visualizar Cliente {{ cliente.id }}</span
                        >
                      </vs-divider>
                      <!-- <vs-row
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="ID do Cliente"
                            v-model="cliente.id"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                      </vs-row>-->
                      <vs-row
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Nome Fantasia"
                            v-model="cliente.nome_fantasia"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>

                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <b-form-group>
                            <b-form-radio-group
                              id="tipo"
                              :disabled="true"
                              v-model="cliente.tipo_pessoa"
                              :options="optionTipo"
                              size="lg"
                              buttons
                              button-variant
                              name="tipo"
                              class="pt-10"
                            ></b-form-radio-group>
                          </b-form-group>
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Razão Social"
                            v-model="cliente.razao_social"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="CPF/CNPJ"
                            v-model="cliente.cpf_cnpj"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Inscrição Municipal"
                            v-model="cliente.inscricao_municipal"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Inscrição Estadual"
                            v-model="cliente.inscricao_estadual"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="RG"
                            v-model="cliente.rg"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Data de Nascimento"
                            v-model="cliente.data_nascimento"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Observação"
                            v-model="cliente.obs"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-end"
                          vs-w="6"
                        >
                          <vs-input
                            label="Segmento"
                            v-model="segmento.nome"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-end"
                          vs-w="6"
                        >
                          <vs-input
                            label="Colaborador"
                            v-model="colaborador.nome"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <vs-divider class="mb-2">Preferências</vs-divider>
                            <vs-row>
                              <vs-col
                                vs-w="3"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <vs-checkbox
                                  class="inputx w-full px-3 py-2"
                                  color="success"
                                  :disabled="true"
                                  v-model="cliente.iss_retido"
                                  >ISS RETIDO</vs-checkbox
                                >
                              </vs-col>
                              <vs-col
                                vs-w="6"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <vs-checkbox
                                  class="inputx w-full px-3 py-2"
                                  color="success"
                                  :disabled="true"
                                  v-model="cliente.relatorio_indicativo"
                                  >RELATÓRIO INDICATIVO</vs-checkbox
                                >
                              </vs-col>
                            </vs-row>
                          </div>
                        </vs-col>
                      </vs-row>
                      <vs-row vs-type="flex" class>
                        <vs-divider
                          border-style="dashed"
                          color="success"
                          class="pt-5 my-0"
                        >
                          <span style="font-size: 17px; font-weight: 700"
                            >Contatos principais</span
                          >
                        </vs-divider>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Email"
                            v-model="cliente.email"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Telefone"
                            v-model="cliente.telefone"
                            :disabled="true"
                            class="inputx w-full px-3 py-2"
                          />
                        </vs-col>
                      </vs-row>
                    </vx-card>
                  </div>
                </div>
              </VuePerfectScrollbar>
            </vs-tab>
            <vs-tab
              icon-pack="feather"
              icon="icon-phone"
              label="Contatos"
              @click="size = 'large'"
            >
              <div class="tab-general w-full">
                <div class="px-3 py-3">
                  <vx-card>
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                    >
                      <span style="font-size: 17px; font-weight: 700"
                        >Contatos Adicionais</span
                      >
                    </vs-divider>
                    <vs-row vs-type="flex" class="w-full">
                      <vs-col vs-w="12">
                        <AddContato
                          :cliente="cliente"
                          class="pb-2"
                        ></AddContato>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </div>
              </div>
            </vs-tab>
            <vs-tab
              icon-pack="feather"
              icon="icon-map-pin"
              label="Enderecos"
              @click="size = 'huge'"
            >
              <div class="tab-general w-full">
                <div class="px-3 py-3">
                  <vx-card>
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                    >
                      <span style="font-size: 17px; font-weight: 700"
                        >Enderecos</span
                      >
                    </vs-divider>
                    <vs-row vs-type="flex" class="w-full">
                      <vs-col vs-w="12">
                        <EditEndereco
                          size="large"
                          :blockLenvNovoModal="true"
                          @editEndereco="saveEndereco"
                          :cliente="cliente"
                        ></EditEndereco>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </div>
              </div>
            </vs-tab>
            <vs-tab
              icon-pack="feather"
              icon="icon-book"
              label="Documentos"
              @click="size = 'large'"
            >
              <div class="tab-general w-full">
                <div class="px-3 py-3">
                  <vx-card>
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                    >
                      <span style="font-size: 17px; font-weight: 700"
                        >Documentos</span
                      >
                    </vs-divider>
                    <vs-row vs-type="flex" class="w-full">
                      <vs-col vs-w="12">
                        <Documentos
                          @close="modalVisualizarCliente = false"
                          :item="cliente"
                          class="pb-2"
                        ></Documentos>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </div>
              </div>
            </vs-tab>
            <vs-tab
              icon-pack="feather"
              icon="icon-paperclip"
              label="Historico"
              @click="size = 'huger'"
            >
              <div class="tab-general w-full">
                <div class="px-3 py-3">
                  <vx-card>
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                    >
                      <span style="font-size: 17px; font-weight: 700"
                        >Historico</span
                      >
                    </vs-divider>
                    <vs-row vs-type="flex" class="w-full">
                      <vs-col vs-w="12" class="pt-5">
                        <Assunto :cliente="cliente"></Assunto>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import EditEndereco from "./components/EditEndereco.vue";
import Documentos from "./components/documentos.vue";
import AddContato from "./components/AddContato.vue";
import Assunto from "./components/assuntoCliente.vue";
export default {
  props: {
    id_cliente: null,
    textColor: null,
    fontSize: null,
  },
  data() {
    return {
      cliente: {},
      size: "large",
      optionTipo: [
        { text: "CPF", value: 1 },
        { text: "CNPJ", value: 2 },
      ],
      optionContato: [
        { text: "E-MAIL", value: 0 },
        { text: "TELEFONE", value: 1 },
      ],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000,
      },
      enderecos: [],
      endereco: {},
      modalVisualizarCliente: false,
      originalData: {},
      error: false,
      segmento: {},
      colaborador: {},
    };
  },
  methods: {
    async openModal() {
      this.modalVisualizarCliente = true;
      await this.$vs.loading({
        container: "#clienteVisualizar" + this.id_cliente,
        scale: 0.6,
      });
      try {
        await this.getCliente();
        await this.getColaborador(this.cliente.id_colaborador);
        await this.getSegmento();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#clienteVisualizar" + this.id_cliente + " > .con-vs-loading"
        );
      }
    },
    async getCliente() {
      try {
        this.cliente = await this.$http.get(`cliente/` + this.id_cliente);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async editar() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          await this.editarCliente();
          //await this.editarEndereco();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async saveEndereco(endereco) {
      this.enderecos.push({
        id_estado: endereco.id_estado,
        id_bairro: endereco.id_bairro,
        id_cidade: endereco.id_cidade,
        id_segmento: endereco.id_segmento,
        logradouro: endereco.logradouro,
        numero: endereco.numero,
        nome: endereco.nome,
        cep: endereco.cep,
        complemento: endereco.complemento,
        lixeira: false,
      });
      this.endereco = endereco;
      //await this.editarEndereco();
    },
    async editarCliente() {
      await this.$vs.loading({
        container: "#clienteVisualizar" + this.id_cliente,
        scale: 0.6,
      });
      try {
        //this.clienteEdit.data_nascimento = new Date(this.clienteEdit.data_nascimento.replace(/-/g,"/"));
        this.cliente = await this.$http.put(
          "cliente/" + this.cliente.id,
          this.cliente
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição do cliente " + this.cliente.id,
        };
        await this.$logger(logData);
        this.$emit("update");
        this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#clienteVisualizar" + this.id_cliente + " > .con-vs-loading"
        );
      }
    },
    async editarEndereco() {
      try {
        const res = await this.$http.post("endereco", [
          this.enderecos,
          { id_cliente: this.cliente.id },
        ]);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição do endereco " + res.id,
        };
        await this.$logger(logData);
        this.$emit("update");
        this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.modalCadastrarCliente = false;
      }
    },
    async excluirCliente() {
      await this.$vs.loading({
        container: "#clienteVisualizar" + this.id_cliente,
        scale: 0.6,
      });
      try {
        const res = await this.$http.delete(`cliente/${this.cliente.id}`);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão do cliente " + res.id,
        };
        await this.$logger(logData);
        this.$emit("update");
        this.$vs.notify(this.$notify.Success);
        this.modalVisualizarCliente = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        await this.$vs.loading.close(
          "#clienteVisualizar" + this.id_cliente + " > .con-vs-loading"
        );
      }
    },
    async mudarStatusCliente() {
      await this.$vs.loading({
        container: "#clienteVisualizar" + this.id_cliente,
        scale: 0.6,
      });
      try {
        const res = await this.$http.put(`cliente/${this.cliente.id}`, {
          lixeira: !this.cliente.lixeira,
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status do cliente " + res.id,
        };
        await this.$logger(logData);
        this.$emit("update");
        this.$vs.notify(this.$notify.Success);
        this.modalVisualizarCliente = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        await this.$emit("update");
        await this.$vs.loading.close(
          "#clienteVisualizar" + this.id_cliente + " > .con-vs-loading"
        );
      }
    },
    async getSegmento() {
      try {
        this.segmento = await this.$http.get(
          `segmento/${this.cliente.id_segmento}`
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getColaborador() {
      try {
        this.colaborador = await this.$http.get(
          `colaborador/${this.cliente.id_colaborador}`
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },

    async cancelar() {
      // Object.assign(this.cliente, this._beforeEditingCache);
      // this.originalData = this._beforeEditingCache = null;
      this.modalVisualizarCliente = false;
    },
  },
  async mounted() {
    await this.openModal();
    // this._beforeEditingCache = Object.assign({}, this.cliente);
  },
  components: { ...components, EditEndereco, AddContato, Assunto, Documentos },
};
</script>
<style lang="scss" scoped>
.con-slot-tabs {
  width: 100%;
}
.con-tab-ejemplo {
  width: 100%;
}

.vs-list--item .list-titles .vs-list--subtitle {
  font-size: 1.3rem;
}

.el-tabs__item.is-active {
  color: #ffca00 !important;
}

.el-tabs__item:hover {
  color: #ffca00 !important;
}

.el-tabs__active-bar {
  background-color: #ffca00 !important;
}
.col {
  width: auto;
}
.vs-tabs--content {
  padding: 0;
}
.input-group-prepend {
  color: #ffca00 !important;
}

.bootstrap-button {
  background-color: #ffca00 !important;
  color: black !important;
  cursor: default !important;
}
.scroll-visualizar-cliente {
  height: 72vh !important;
}
.nome-cliente-small {
  font-weight: 600;
  font-size: 10px;
}
.nome-cliente-medium {
  font-weight: 600;
  font-size: 14px;
}
</style>
